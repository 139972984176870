import React, { useState, useEffect } from 'react';
import { API_BASE_URL, EXAM_REQUIREMENTS } from './constants';
import StepStatus from './StepStatus.jsx';
import Spinner from './Spinner.jsx';

function CheckWork(props) {
  // const [response, setResponse] = useState(null);
  const [steps, setSteps] = useState(EXAM_REQUIREMENTS[props.certCategory.steps] || []);
  const [workChecked, setWorkChecked] = useState(false)
  const [isCheckingWork, setIsCheckingWork] = useState(false)
  // const skilljarPublishedCourseId = "tm1cq4re7mqi"
  // const skilljarLessonId = "1au9kwbytctd"

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const courseName = urlParams.get('courseName'); 
    setSteps(EXAM_REQUIREMENTS[courseName].steps);
  }, []);

  function setStepStatuses(statuses) {
    const updatedSteps = steps.map(step => ({
      ...step,
      status: statuses[step.subject]
    }));

    setSteps(updatedSteps);

    checkIfComplete(updatedSteps)
  }

  async function markLessonComplete() {
    try {
      const res = await fetch(`${API_BASE_URL}/api/complete-lesson?email=${props.email}&courseName=${props.certKey}`);
      // const data = await res.json();

    } catch (error) {
      console.log("LESSON COMPLETION FAILED")
      // console.error(error);
    }
  }

  function checkIfComplete(updatedSteps) {
    const correctStepsLength = updatedSteps.filter((step) => step.status === true).length
    // if (steps.length == correctStepsLength) {

    if (steps.length === correctStepsLength) {
      markLessonComplete()
    }
  }

  const handleCheckWork = async () => {
    try {
      setIsCheckingWork(true)
      const res = await fetch(`${API_BASE_URL}/api/check-work/${props.certKey}?company_id=${props.companyId}&project_id=${props.projectId}&token=${props.token}`);
      const data = await res.json();
      setWorkChecked(true)      
      setStepStatuses(data)

      // setResponse(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCheckingWork(false)
    }
  };

  // const [status, setStatus] = useState(null);
  return (
    <div className="main-container">      

      <div className='steps-container'>
        {steps.map((step, i) => <StepStatus workChecked={workChecked} step={step} index={1} status={step.status} delay={`${0.5 * i}s`} />)}
      </div>

      <div className='buttons-container'>
        
        <button className="go-to-project-button">
          <a style={{'text-decoration': "none", "color": 'white'}} href={`https://app.procore.com/${props.projectId}/project/home`} target="_blank">Go to Project</a>
        </button>
        
        <button className="check-work-button"
          onClick={handleCheckWork}
        >
          {/* {isCheckingWork ? <Spinner /> : "Check Your Work" } */}
          Check Your Work
        </button>
      </div>
      {/* {response && <div>{response.message}</div>} */}
    </div>
  );
}

export default CheckWork;
