import React, { useState } from 'react';
import { initialize } from '@procore/procore-iframe-helpers';
import { API_BASE_URL, FRONTEND_BASE_URL } from './constants';

import CodeSubmitter from './CodeSubmitter';
import Spinner from './Spinner.jsx';
import { useNavigate } from 'react-router-dom';

const ProcoreLoginButton = (props) => {
  // const apiBaseUrl = "https://a6cf-2600-4040-9a90-6700-a47d-29a6-9191-af07.ngrok-free.app"
  // const redirectUri = `${apiBaseUrl}/auth/procore/callback`;

  const context = initialize();

  // const [userName, setUserName] = useState('');
  // const [userEmail, setUserEmail] = useState('');
  const [didCloseAuthWindow, setDidCloseAuthWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [code, setCode] = useState('');

    // const redirectUri = `urn:ietf:wg:oauth:2.0:oob`;
    // const redirectUri = `${API_BASE_URL}/auth/procore/callback`;
    // const redirectUri = `${API_BASE_URL}/auth/procore/callback`;  

  const navigate = useNavigate();

  // const redirectToHome = () => {
      // navigate('/');
      // window.location.href = `/?courseName=${props.certKey}`;
  // };

  const authUser = () => {
    const clientId = '89bca2b85b69bf9be0f3620f3005d4685b184be8be49ff69bb34ee36403e51e2';
    // const redirectUri = `http://localhost:3000/auth/procore/callback`;
    // const redirectUri = `urn:ietf:wg:oauth:2.0:oob`;
    const redirectUri = `${FRONTEND_BASE_URL}/auth-window`;
    const authorizeUrl = `https://login.procore.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}`;
    context.authentication.authenticate({
      // Set this to a URL on your domain that will start the authentication process.
      // This URL points to a route that calls the /oauth/authorize endpoint with client_id,
      // response_type, and redirect_uri query parameters...
      // https://login.procore.com/oauth/authorize?client_id=<ClientID>&response_type=<ResponseType>&redirect_uri=<RedirectID>
      // url: "/auth/procore",
      url: authorizeUrl,

      // Send the end user to the /main route of your embedded app if authentication is successful.
      // This can be any route you choose.
      // You can optionally include a payload as a function parameter (i.e., function(payload))
      onSuccess: function (payload) {
        console.log("SUCCESS!", payload)
        window.location = "/main";
      },

      // Error handling function that runs if authentication fails. Here we are just logging the error
      // to the console, but you will want to display an error to the user. This
      // function can be triggered by you, or will be triggered automatically if the
      // user closes the authenication window.
      onFailure: function (error) {
        console.log("FAILURE!")
        console.log(error);
        props.fetchUser()
        // props.getUserDetails()
        // const accessToken = localStorage.getItem('access_token')
        // if (accessToken) {
          // setDidCloseAuthWindow(true)
          // 
        // }
      },
    })
  }

  async function authenticateUser(e, code) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/auth/procore/callback?code=${code}&courseName=${props.certKey}`, {
        method: 'GET'
      });
      if (response.ok) {
        const userData = await response.json();
        // Handle the response data
        // console.log('Success:', data);
        localStorage.setItem('access_token', userData.tokens.access_token);
        localStorage.setItem('refresh_token', userData.tokens.refresh_token);

        props.setUser(userData.user, userData.projectId)
      } else {
        // Handle errors
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      // Handle network errors
      console.error('Network Error:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    // <a href={authorizeUrl}>
      <div className="auth-container">
        {/* <input 
          type="text"
          value={userEmail} 
          onChange={e => setUserEmail(e.target.value)}       
        /> */}
          <button className="login-button"
            onClick={
              () => authUser()
            }
          >
            Login with Procore
          </button>
      </div>
      
    // </a>
  );
};

export default ProcoreLoginButton;
