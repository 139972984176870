import React, { useEffect, useRef } from 'react';
import { API_BASE_URL } from './constants';

const AuthWindow = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);    
    const code = urlParams.get('code');
    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) {
            return;
        }        
        
        hasRun.current = true;

        const checkAuth = async () => {
            try {
                console.log("checking auth")
                const response = await fetch(`${API_BASE_URL}/auth/procore/callback?code=${code}`);
                if (response.status === 200) {
                    const data = await response.json();
                    let tokens = data.tokens
                    // localStorage.setItem('access_token', tokens.access_token);
                    // localStorage.setItem('refresh_token', tokens.refresh_token);

                    document.cookie = `access_token=${tokens.access_token}; max-age=3600; SameSite=None; Secure; path=/`;
                    window.close();
                } else {
                    console.log('Authentication failed');
                }
            } catch (error) {
                console.error('Error during API call', error);
            }
        };

        checkAuth();
    }, []);

    return (
        <div>
            <p>Authenticating...</p>
        </div>
    );
};

export default AuthWindow;
