import React, { useState, useEffect } from 'react';
// import './StepStatus.css';

const StepStatus = ({ step, index, status, delay, workChecked }) => {
    const [displayStatus, setDisplayStatus] = useState(null);

    useEffect(() => {
      if (status !== null) {
        const timer = setTimeout(() => {
          setDisplayStatus(status);
        }, parseFloat(delay) * 1000);
        return () => clearTimeout(timer);
      }
    }, [status, delay]);

    //   const [status, setStatus] = useState(true);

//   useEffect(() => {
//     if (checkStep) {
//       setStatus(checkStep());
//     }
//   }, [checkStep]);

  return (
    // <div className={`step-status ${status === true ? 'correct' : status === false ? 'incorrect' : ''}`}>
    <div className={`step-status`}>
      {/* <div className="step-number">{index + 1}</div> */}
      {!workChecked && <div className="step-indicator placeholder">○</div>}
      {!!workChecked && status === true && <div style={{ '--delay': delay }} className="step-indicator correct">✔</div>}
      {!!workChecked && status === false && <div style={{ '--delay': delay }} className="step-indicator incorrect">✘</div>}
      <div className="step-description">{step.description}</div>
    </div>
  );
};

export default StepStatus;

