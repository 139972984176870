export const LABS_KEY = "alskdjfkl;aj"
export const LABS_SECRET = "asdlkfj;aksjdhflkjahsdf"
export const AUTH_TOKEN = "aslkjdfaskdjhflhjkasdfasdlkjfhjlkashdfsdf"
// export const API_BASE_URL = "http://localhost:3000"
// export const API_BASE_URL = "https://a6cf-2600-4040-9a90-6700-a47d-29a6-9191-af07.ngrok-free.app"

export const API_BASE_URL = "https://mindtouch-user-management.herokuapp.com"
// export const API_BASE_URL = "http://localhost:3000"

export const FRONTEND_BASE_URL="https://procore-drills-react.pages.dev"
// export const FRONTEND_BASE_URL="http://localhost:3001"

export const SKILLJAR_KEY = "c2stbGl2ZS01NzkwNmEyZDUyMjQ5OGFjNjMyODM2ZGY0N2Y2MzMwMDQxOTkyM2Fi" 

export const EXAM_REQUIREMENTS = {
    rfi: {
        name: "RFI",
        steps: [
            {
                subject: 'subject',
                description: 'Subject reads "Conference Room Wall Paint Color"',
                status: null
            },
            {
                subject: 'rfi_manager',
                description: 'RFI Manager is "Pat Project Manager"',
                status: null
            },
            {
                subject: 'assignee',
                description: 'Assignees include "Archie Architect (Architect Design Firm)"',
                status: null
            },
            {
                subject: 'question',
                description: 'Question is "What color should the interior walls be painted in the Executive Conference Room?"',
                status: null
            }        
        ]
    },
    submittal: {
        name: "Submittal",
        steps: [
            {
                subject: 'title',
                description: 'Title reads "Hollow Metal Doors and Frames Shop Drawings',
                status: null
            },
            {
                subject: 'responsible_contractor',
                description: '"Birch Door Co." is the Reponsible Contractor',
                status: null
            },
            {
                subject: 'submittal_manager',
                description: '"Pat Project Manager" is the Submittal Manager',
                status: null
            },
            {
                subject: 'description',
                description: 'The description reads "HM Doors and Frames Submittal"',
                status: null
            }                      
        ]
    },
    meeting: {
        name: "Meeting",
        steps: [
            {
                subject: 'title',
                description: 'Title: "Weekly Progress Meeting"',
                status: null
            },
            {
                subject: 'attendees',
                description: 'The list of attendees includes "Cassidy (Company Admin)"',
                status: null
            },
            {
                subject: 'meeting_topic',
                description: 'Agenda includes item titled "Schedule", assigned to Cassidy',
                status: null
            }           
        ]
    },
    "rfi-response": {
        name: "RFI Response",
        steps: [
            {
                subject: 'plain_text_body',
                description: 'Reply with “Use hardware group 2 for both doors 120 and 130”.',
                status: null
            },
            {
                subject: 'official',
                description: 'Mark the RFI Response "Official".',
                status: null
            },
            {
                subject: 'status',
                description: 'Close the RFI.',
                status: null
            }           
        ]
    },
    "submittal-template": {
        name: "Submittal Template",
        steps: [
            {
                subject: 'name',
                description: 'Name the template "Standard Workflow"',
                status: null
            },
            {
                subject: 'submitter',
                description: 'Assign "Danny Door Contractor" as a submitter',
                status: null
            },
            {
                subject: 'approver',
                description: 'Assign "Pat Project Manager" as an approver',
                status: null
            }                                   
        ]
    },
    "drawing": {
        name: "Drawings",
        steps: [
            {
                subject: "drawing_set",
                description: "Upload the 'Shop Drawings' Set",
                status: null
            },
            {
                subject: "status",
                description: "review the drawing set",
                status: null
            }
        ]
    }
}