import React, { useState } from 'react';
// import { API_BASE_URL } from './constants';

const CodeSubmitter = (props) => {
  const [code, setCode] = useState('');

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//   };

  return (
    <form className='auth-code-form' onSubmit={(e) => props.handleSubmit(e, code)}>
      <label htmlFor="codeInput">Enter Code:</label>
      <input
        type="text"
        id="codeInput"
        value={code}
        onChange={handleInputChange}
      />
      <button className="auth-code-submit-button" type="submit">Submit</button>
    </form>
  );
};

export default CodeSubmitter;
