import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
import ProcoreLoginButton from "./ProcoreLoginButton.jsx"
import ProcoreAuth from './ProcoreAuth.jsx';
import CheckWork from "./CheckWork.jsx"
import Spinner from './Spinner.jsx';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { API_BASE_URL, EXAM_REQUIREMENTS } from './constants';
import AuthWindow from './AuthWindow.js';

function App() {
  // const accessToken = localStorage.getItem('access_token');
  const accessToken = getCookie('access_token')
  // const [userId, setUserId] = useState('12345');
  // const [userName, setUserName] = useState('Leon Malisov');
  // const [userEmail, setUserEmail] = useState('leon.malisov@procore.com');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');

  const [certCataegory, setCertCataegory] = useState('');
  const [certKey, setCertKey] = useState('');
  const [companyId, setCompanyId] = useState(200650);
  const [projectId, setProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // const [userId, setUserId] = useState('123');
  // const [userName, setUserName] = useState('Leon Malisov');
  // const [userEmail, setUserEmail] = useState('leon.malisov@procore.com');
  // const [certCataegory, setCertCataegory] = useState('rfi');
  // const [companyId, setCompanyId] = useState('200650');
  // const [projectId, setProjectId] = useState('2451244d');
  
  useEffect(() => {
    const isAuthWindow = location.pathname === '/auth-window';

    if (!isAuthWindow) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const courseName = urlParams.get('courseName');
      // const courseTitle = getCourseTitle(courseName)
      const courseTitle = EXAM_REQUIREMENTS[courseName].name
      getUserDetails()
  
      setCertCataegory(courseTitle)
      setCertKey(courseName)    
    }
  }, []);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  } 

  function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  }

  async function getUserDetails()  {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const courseName = urlParams.get('courseName'); 
    const courseVertical = urlParams.get('courseVertical');
    
    try {
      setLoading(true)
      // const response = await fetch(`${'https://a6cf-2600-4040-9a90-6700-a47d-29a6-9191-af07.ngrok-free.app'}/api/user?courseName=${courseName}&access_token=${accessToken}`, {
      //   method: 'GET'
      // });

      const response = await fetch(`${API_BASE_URL}/api/user?courseName=${courseName}&access_token=${getCookie('access_token')}`, {
        method: 'GET'
      });
      
      const userData = await response.json()
      updateUser(userData.userInfo, userData.projectId);

      // if (response.ok) {
      //   const data = await response.json();
        
      //   // setUserId(data.user.id)
      //   // setUserEmail(data.user.login)
      //   // setUserName(data.user.name)
      //   // setCompanyId(data.companyId)
      //   // setProjectId(data.projectId)
      //   setLoading(false)
      // } else {
      //   throw new Error('Failed to fetch data');
      // }
    } catch (error) {
      // setError(error.message);
    } finally {
      setLoading(false);
    }    
  }

  const resetSeededExam = async (courseName) => {
    try {
      const res = await fetch(`${API_BASE_URL}/api/reset/${certKey}?company_id=${companyId}&project_id=${projectId}&token=${accessToken}`);
      // const data = await res.json();
      console.log("SEEDED EXAM: " + res.status)

      // setResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  const seedExam = async () => {
    // debugger
    if (certKey == 'rfi') {
      return
    }
    
    var courseKey = certKey || 'rfi-response'
    try {
      const seedUrl = `${API_BASE_URL}/api/seed/${courseKey}?company_id=${companyId}&project_id=${projectId}&token=${accessToken}`
      // debugger
      const res = await fetch(seedUrl);
      // const data = await res.json();
      console.log("SEEDED EXAM: " + res.status)

      // setResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  function updateUser(user, projectId) {
    setUserId(user.id);
    setUserEmail(user.login);
    setUserName(user.name.trim());
    setProjectId(projectId)
    
    seedExam()
    // setTimeout(seedExam, 1000);
  }

  function logOut() {
    // localStorage.setItem('access_token', "");
    // localStorage.setItem('refresh_token', "");

    deleteCookie("access_token")
    deleteCookie("refresh_token")

    updateUser({id: "", login: "", name: ""})
  }

  return (
      <Routes>
        <Route exact path="/" element={
          <div className="App">
            {userEmail 
              ?
                <React.Fragment>
                  {/* <h1 className="cert-title" style={{"color": "white"}}>Advanced Certification: {certCataegory} </h1> */}
                  <div className="user-name">{userName || userEmail}</div>
                  {/* <div className="cert-title" style={{"color": "white"}}>ADVANCED CERTIFICATION: {certCataegory.toUpperCase()}S</div> */}
                  <div className="cert-description">Please create an {certCataegory} with the following attributes:</div>
                  {
                    <CheckWork certCategory={certCataegory} certKey={certKey} companyId={companyId} projectId={projectId} token={accessToken} email={userEmail} /> 
                  }

                  <div className="user-settings">
                    <button className="reset-exam-button" onClick={resetSeededExam}>RESET EXAM</button>
                    <button className="log-out" onClick={logOut}>Log out</button>
                  </div>

                  {/* <div className="spinner-container">
                    {loading ? <Spinner /> : <div></div>}
                  </div> */}

                </React.Fragment>
              :
                <div className="spinner-container">
                  {loading ? <Spinner /> : <ProcoreLoginButton setUser={updateUser} certKey={certKey} fetchUser={getUserDetails} />}
                </div>
            }
          </div>
        } />        
        <Route path="/auth/procore" element={<ProcoreAuth />} />
        <Route path="/main" element={<div>WHAT THEY DO</div>} />
        <Route path="/auth-window" element={<AuthWindow />} />
      </Routes>              
  );
}

export default App;