import React, { useEffect } from 'react';

const ProcoreAuth = () => {
  // Define the Client ID and Redirect URI from the Developer Portal
  const clientId = "89bca2b85b69bf9be0f3620f3005d4685b184be8be49ff69bb34ee36403e51e2"; // Replace with your actual Client ID
  const redirectUri = 'https://a6cf-2600-4040-9a90-6700-a47d-29a6-9191-af07.ngrok-free.app/auth/procore/callback'; // Replace with your actual Redirect URI

  // Define the function to initiate the OAuth authorization flow
  const fetchProcoreAuth = () => {
    // Construct the authorization URL with the specified query parameters
    const authUrl = `https://login.procore.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}`;

    // Redirect the user to the authorization URL
    window.location.href = authUrl;
  };

  // Define the function to make the API call
//   const fetchProcoreAuth = async () => {
//     try {
//       const response = await fetch('/auth/procore');
//       const data = await response.json();
//       // Handle the response data as needed
//       console.log(data);
//     } catch (error) {
//       // Handle any errors that occur during the API call
//       console.error('Error fetching Procore Auth:', error);
//     }
//   };

  // Use the useEffect hook to call the fetchProcoreAuth function when the component mounts
  useEffect(() => {
    fetchProcoreAuth();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      {/* Render any UI related to the ProcoreAuth component here */}
      <div>Procore Auth Component</div>
    </div>
  );
};

export default ProcoreAuth;
